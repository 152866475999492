import React from 'react';

import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

import './ress.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/Auth';
import { MainProvider } from './contexts/Main';

import * as Sentry from '@sentry/react';
import FallbackError from './components/common/FallbackError';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://e4e70de91705422db230ce673014dd38@o4504243829866496.ingest.sentry.io/4504243830784000',
    // integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactPixel.init('1632320730601227');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

ReactGA.initialize('G-D1Q6TVYLS2');

root.render(
    // <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
        <BrowserRouter>
            <AuthProvider>
                <MainProvider>
                    <HelmetProvider>
                        <Helmet>
                            <script type="text/javascript">{`
                    (function(g,u,i,d,e,s){g[e] = g[e] || [];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n, arguments])};};var m=['previewGuide','finishPreview','track','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]] = ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','M5974580PQGID');
                            `}</script>
                        </Helmet>
                        <App />
                    </HelmetProvider>
                </MainProvider>
            </AuthProvider>
        </BrowserRouter>
    </Sentry.ErrorBoundary>
    // </React.StrictMode>
);

const sendAnalytics = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendAnalytics);